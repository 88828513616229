import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ReactPlayer from 'react-player'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'

const Component = ({ content, settings, data, locale }) => {
  const videos = data?.videos?.nodes
  const videosLocale = videos?.map((item) => item.frontmatter[locale])

  const videoMatch = videosLocale.find(
    (item) => item.slug === content.videoSlug
  )
  if (!videoMatch) return false

  const [videoUrl, setVideoUrl] = useState('')

  const { loop, muted, controls, autoplay } = settings || {}
  const [hasPoster, setHasPoster] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const hasPosterClass = hasPoster ? styles.hasPoster : ''
  const hasAutoplayClass = autoplay ? styles.hasAutoplay : ''

  const getVideoUrl = () => {
    let getVideoUrl = ''
    if (typeof window !== 'undefined') {
      // get the screen width pixels
      const screenRatio = window.devicePixelRatio || 1
      const screenWidth = window.innerWidth * screenRatio

      // get the nearest matching screen width
      const videoWidths = [1920, 1280, 960]
      const modifier = 1
      let widthMatch = 1920 // start with max
      videoWidths.forEach((thisWidth) => {
        if (thisWidth > screenWidth * modifier) widthMatch = thisWidth // find the nearest match which is greater than the current screen width
      })

      // get the video that matches the matching screen width
      // const videoMatch = sizes?.find((video) => {
      //   return video.file.publicURL.includes(widthMatch + 'w')
      // })
      // getVideoUrl = videoMatch?.file.publicURL
      getVideoUrl = videoMatch.sizes[`_${widthMatch}w`].publicURL
    }
    setVideoUrl(getVideoUrl)
  }

  useEffect(() => {
    getVideoUrl()
    if (autoplay) {
      setIsPlaying(true)
    }
    // window.addEventListener('resize', getVideoUrl)
    // return () => window.removeEventListener('resize', getVideoUrl)
  }, [])

  const handlePosterClick = () => {
    if (isPlaying) setIsPlaying(false) // for wechat, reset autoplay state setting
    setTimeout(() => {
      setIsPlaying(true)
    }, 1)
  }

  return (
    <div
      className={`${styles.component} ${hasPosterClass} ${hasAutoplayClass}`}
    >
      <div className={styles.poster} onClick={handlePosterClick}>
        <div className={styles.image}>
          <ImageWrap image={videoMatch.image} />
        </div>
        <div className={styles.button}>
          <div className={styles.triangle} />
        </div>
      </div>
      {videoUrl && (
        <ReactPlayer
          url={videoUrl}
          loop={loop}
          playing={isPlaying}
          muted={muted}
          controls={controls}
          playsinline
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload noplaybackrate',
                disablePictureInPicture: true,
                // poster: videoMatch.image?.publicURL,
              },
            },
          }}
          width='100%'
          height='100%'
          onStart={() => setHasPoster(false)}
        />
      )}
    </div>
  )
}

export default function Data({ content, settings, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          videos: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "video" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                    publicURL
                  }
                  sizes {
                    _1920w {
                      publicURL
                    }
                    _1280w {
                      publicURL
                    }
                    _960w {
                      publicURL
                    }
                  }
                }
                zh {
                  slug
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                    publicURL
                  }
                  sizes {
                    _1920w {
                      publicURL
                    }
                    _1280w {
                      publicURL
                    }
                    _960w {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Component
          content={content}
          settings={settings}
          data={data}
          locale={locale}
        />
      )}
    />
  )
}
