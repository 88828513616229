import React from 'react'
import IconWechat from 'assets/svg/social/wechat.svg'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const Social = ({
  version = 'light',
  isLeft = false,
  isLeftMobile = false,
}) => {
  const versionClass =
    version === 'dark' ? styles.versionDark : styles.versionLight
  const isLeftClass = isLeft ? styles.isLeft : ''
  const isLeftMobileClass = isLeftMobile ? styles.isLeftMobile : ''
  return (
    <div className={`${versionClass} ${isLeftClass} ${isLeftMobileClass}`}>
      <div className={styles.items}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconWechat />
          </div>
          <div className={styles.qr}>
            <StaticImage
              src='../../assets/images/qr-wechat.png'
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Social
