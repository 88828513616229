import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import LinkWrap from 'components/LinkWrap'
import Social from 'components/Social'

const Component = ({ data, locale }) => {
  let menu = data.menus.nodes[0].frontmatter[locale]
  let pages = data.pages.nodes
  const pagesLocale = pages.map((page) => page.frontmatter[locale])
  menu.main.forEach((item) => {
    item.data = pagesLocale.find((page) => page.slug === item.page)
  })

  const copyright = {
    en: `©Monroe® 2020. <a href="https://lantern.digital" target="_blank">网站建设</a> <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022025009号-2</a>`,
    zh: `©Monroe® 2020. <a href="https://www.lanterndigital.com.cn" target="_blank">网站建设</a> <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022025009号-2</a>`,
  }

  return (
    <footer className={styles.footerAccueil}>
      <div className={`container ${styles.container}`}>
        <div className={`row ${styles.row}`}>
          <ul className='list-inline'>
            {menu.main.map((item, i) => (
              <M0 content={item} key={i} />
            ))}
          </ul>
          <div className={styles.copyright}>
            <p dangerouslySetInnerHTML={{ __html: copyright[locale] }} />
            <Social />
          </div>
        </div>
      </div>
    </footer>
  )
}

const M0 = ({ content }) => {
  const { type, data, text, url } = content
  if (type === 'PageLink') {
    return (
      <li className={`list-inline-item ${styles.listInlineItem}`}>
        <LinkWrap to={data.path}>{data.title}</LinkWrap>
      </li>
    )
  }
  if (type === 'ExternalLink') {
    return (
      <li className={`list-inline-item ${styles.listInlineItem}`}>
        <a href={url} target='_blank'>
          {text}
        </a>
        <span>&nbsp;</span>
      </li>
    )
  }
  return <div />
}

// const Social = () => {
//   return (
//     <div className={`col-3 ${styles.social}`}>
//       <ul className='list-inline'>
//         <li className={`list-inline-item ${styles.item}`}>
//           <a href='https://twitter.com/MonroeIntelSusp' target='_blank'>
//             <i className='fab fa-twitter'></i>
//           </a>
//         </li>
//         <span>&nbsp;</span>
//         <li className={`list-inline-item ${styles.item}`}>
//           <a
//             href='https://www.linkedin.com/showcase/suspension'
//             target='_blank'
//           >
//             <i className='fab fa-linkedin-in'></i>
//           </a>
//         </li>
//         <span>&nbsp;</span>
//         <li className={`list-inline-item ${styles.item}`}>
//           <a
//             href='https://www.youtube.com/channel/UCNeLQFVWAZ6i0G982KCldCA'
//             target='_blank'
//           >
//             <i className='fab fa-youtube'></i>
//           </a>
//         </li>
//         <span>&nbsp;</span>
//       </ul>
//     </div>
//   )
// }

export default function Data({ locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          menus: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: { templateKey: { eq: "menu" }, slug: { eq: "footer" } }
              }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  main {
                    type
                    page
                    text
                    url
                  }
                }
                zh {
                  title
                  main {
                    type
                    page
                    text
                    url
                  }
                }
              }
            }
          }
          pages: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "page" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  path
                }
                zh {
                  slug
                  title
                  path
                }
              }
            }
          }
        }
      `}
      render={(data) => <Component data={data} locale={locale} />}
    />
  )
}
